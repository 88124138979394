<template>
	<div class="hello">
		<div class="title">问卷调查</div>
		<div class="que" v-for="(item,index) in queList">
			<div class="queOne" v-if="item.type == 1">
				<p>{{item.name}}</p>
				<el-radio-group v-model="ans[index].ansID" class="ra">
					<el-radio :label="itemChild.id" v-for="(itemChild,indexChild) in item.select" size="large">
						{{itemChild.name}}
					</el-radio>
				</el-radio-group>
			</div>
			<div class="queOne" v-if="item.type == 2">
				<p>{{item.name}}</p>
				<el-checkbox-group v-model="ans[index].ansID" class="ck">
					<el-checkbox :label="itemChild.id" v-for="(itemChild,indexChild) in item.select">{{itemChild.name}}
					</el-checkbox>
				</el-checkbox-group>
			</div>
			<div class="queOne" v-if="item.type == 3 || item.type == 4">
				<p>{{item.name}}</p>
				<!-- <textarea name="ans" v-model="ans" id="" cols="30" rows="10"></textarea> -->
				<el-input v-model="ans[index].ansID" class="ans" type="textarea" placeholder="请输入" />
			</div>
		</div>
		<div class="btn">
			<el-button @click="sub" type="primary">提交</el-button>
		</div>

	</div>
</template>

<script>
	// 引入axios
	import axios from 'axios';
	import {
		ref
	} from 'vue'
	// 引入端口配置文件
	import API_LIST from '../../list.config.js';
	import qs from 'qs'
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'


	export default {
		name: "HelloWorld",
		props: {

		},
		data() {
			return {
				queList: [],
				ans: [],
				loadingInstance: '',
			}

		},
		watch: {

		},
		created() {
			this.loadingInstance = this.$loading({
				fullscreen: true
			})
			// this.getQueryVariable'
			this.getData()
		},
		mounted() {

		},
		methods: {
			// 登录
			getData() {
				const that = this;
				// this.fullscreenLoading = true;
				let obj = {
					ca: this.getQueryVariable("ca"),
					mc3: this.getQueryVariable("mc3"),
				}
				// let a = qs.stringify(obj)
				// console.log(a)

				axios.post(API_LIST.dQuestion, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						console.log(_d)
						this.loadingInstance.close()
						if (_d.data.status == 200) {

							_d.data.list.question.forEach(item => {
								that.ans.push({
									queID: item.id,
									type: item.type,
									ansID: []
								})
							})
							console.log(that.ans)

							that.queList = _d.data.list.question
							that.meet_content = _d.data.list.meet_content

						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			// 获取网址
			getQueryVariable(variable) {
				var query = window.location.search.substring(1);
				var vars = query.split("&");
				for (var i = 0; i < vars.length; i++) {
					var pair = vars[i].split("=");
					if (pair[0] == variable) {
						return pair[1];
					}
				}
				return (false);
			},
			// 点击上一页
			shang() {
				const that = this;
				if (that.page > 1) {
					that.page--;
					that.his()
				} else {
					ElMessage({
						message: '已经是第一页了',
						type: 'warning',
					})
				}
			},
			// 点击下一页
			xia() {
				const that = this;
				if (that.page < that.total_page) {
					that.page++;
					that.his()
				} else {
					ElMessage({
						message: '已经是最后一页了',
						type: 'warning',
					})
				}
			},
			// 核验
			sub(str) {
				const that = this;
				console.log(this.ans)
				// return
				let obj = {
					ca: this.getQueryVariable("ca"),
					mc3: this.getQueryVariable("mc3"),
					data2:this.ans
				}
				if(that.meet_content == ''||that.meet_content == null){
					axios.post(API_LIST.add_answer, obj, {
							headers: {
								'Content-Type': 'application/json'
							} //加上这个
						})
						.then(_d => {
							console.log(_d)
							if (_d.data.status == 200) {
								ElMessage({
									message: _d.data.msg,
									type: 'success',
									duration: 2000
								})
							} else {
								ElMessage({
									message: _d.data.msg,
									type: 'warning',
									duration: 2000
								})
					
							}
						})
						.catch(function(error) {
							console.log(error);
						});
					
				}else{
					axios.post(API_LIST.edit_answer, obj, {
							headers: {
								'Content-Type': 'application/json'
							} //加上这个
						})
						.then(_d => {
							console.log(_d)
							if (_d.data.status == 200) {
								ElMessage({
									message: _d.data.msg,
									type: 'success',
									duration: 2000
								})
							} else {
								ElMessage({
									message: _d.data.msg,
									type: 'warning',
									duration: 2000
								})
					
							}
						})
						.catch(function(error) {
							console.log(error);
						});
				}
				// let a = qs.stringify(obj)
				// console.log(a)
				

			},
			// 取消
			cancel() {
				this.data = {
					product: [],
					user: {},
				}
				this.box_show = false;
				this.focus_btn();
			},
			// 确定
			yes() {
				const that = this;
				if (this.data.order_id == '') {
					return false;
				}
				let Base64 = require('js-base64').Base64;
				let aaa = Base64.encode(this.data.user.id)
				let obj = {
					order_id: this.data.order_id,
					type: this.data.type,
					u_id: aaa,
					device_id: this.device_id,
					canteen_id: this.canteen_id,
				}
				let a = qs.stringify(obj)
				console.log(a)

				axios.post(API_LIST.check_order, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						console.log(_d)
						that.focus_btn()
						this.box_show = false;
						// that.chu();
						console.log(3333333)
						if (_d.data.status == 200) {
							console.log(_d.data.list)
							_d.data.list.no = this.renList.length + 1
							console.log(_d.data.list)
							console.log(this.renList)
							this.renList.unshift(_d.data.list)
							// that.chu();
							ElMessage({
								message: '核验完成',
								type: 'success',
							})
							this.data = {
								product: [],
								user: {},
							}
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})
							this.erweima = ''
							this.data = {
								product: [],
								user: {},
							}
						}
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			// 
			add0(m) {
				return m < 10 ? '0' + m : m
			},
			format(shijianchuo) {
				//shijianchuo是整数，否则要parseInt转换
				var time = new Date(shijianchuo);
				var y = time.getFullYear();
				var m = time.getMonth() + 1;
				var d = time.getDate();
				var h = time.getHours();
				var mm = time.getMinutes();
				var s = time.getSeconds();
				return y + '-' + this.add0(m) + '-' + this.add0(d);
			},
			// 获取光标
			focus_btn() {
				document.getElementById('inputId').focus()
			},
			// 
			changes() {
				const that = this;
				this.page = 1;
				that.date = that.datevalue;
				that.his()
			},
			// 点击查看详情
			detail(e) {
				console.log(e)

				this.centerDialogVisible = true;
				this.detail_goods = e;
			},
			// 初始列表
			chu() {
				var query = this.$route.query;
				this.device_id = query.device_id;
				this.canteen_id = query.canteen_id;
				this.date = this.format(Date.parse(new Date()))
				console.log(this.date + 111111)

				let obj = {
					device_id: this.device_id,
					canteen_id: this.canteen_id,
				}
				let a = qs.stringify(obj)
				console.log(a)

				axios.post(API_LIST.examine_list, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						console.log(_d)
						this.loading = false;
						if (_d.data.status == 200) {
							this.renList = _d.data.list
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {
						console.log(error);
					});
			}


		}
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.hello {
		// height: 100%;
		background-color: #eee;
		box-sizing: border-box;
		padding: 10px 15px 25px 15px;

		// text-align:center;
		.title {
			background-color: #fff;
			font-size: 16px;
			font-weight: 800;
			box-sizing: border-box;
			padding: 10px 15px;
			border-left: 3px solid #1E90FF;
			margin: 0 0 20px 0;
		}
	}

	.que {
		display: flex;
		flex-direction: column;
		background-color: #fff;
		margin: 0 0 20px 0;
		box-sizing: border-box;
		padding: 10px 20px;
		border-radius: 15px;

		.queOne {

			p {
				font-size: 18px;
				margin: 0 0 10px 0;
			}
		}

	}

	.ra,
	.ck {
		display: flex;
		flex-direction: column;
		// background-color: #f00;
	}

	.btn {
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin: 40px 0;
		padding: 0 0 30px 0;
	}
</style>
