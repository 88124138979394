// 端口配置文件
const port = 80;
const BASEURL = 'https://dltest.uzhikang.com/';

const API_LIST = {
	// 问题列表
	dQuestion: BASEURL + 'apihg/Meetapi/dQuestion',
	// 回答
	add_answer: BASEURL + 'apihg/Meetapi/add_answer',
	// 修改
	edit_answer: BASEURL + 'apihg/Meetapi/edit_answer',
	// 科室会上传图片
	uploadNew: BASEURL + 'apihg/Meet1/uploadNew',
	// 上传页数据
	pc_submit_page: BASEURL + 'apihg/Index/pc_submit_page',
	// 图片删除
	del_img: BASEURL + 'apihg/Meet1/del_img',
	// 上传ppt
	submit_ppt: BASEURL + 'apihg/Meet1/submit_ppt',
	// PC端保存会后总结
	submit_content: BASEURL +'apihg/Meet1/submit_content',
	// PC端删除会后总结文本
	del_content: BASEURL +'apihg/Meet1/del_content'
}

module.exports = API_LIST;
